import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// ----------------------------------------------------------------------
export default function ScrollToTop() {
    var pathname = useLocation().pathname;
    useEffect(function () {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
}
