import SimpleBar from 'simplebar-react';
// @mui
import { alpha, styled } from '@mui/material/styles';
// ----------------------------------------------------------------------
export var StyledRootScrollbar = styled('div')(function () { return ({
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
}); });
export var StyledScrollbar = styled(SimpleBar)(function (_a) {
    var theme = _a.theme;
    return ({
        maxHeight: '100%',
        '& .simplebar-scrollbar': {
            '&:before': {
                backgroundColor: alpha(theme.palette.grey[600], 0.48),
            },
            '&.simplebar-visible:before': {
                opacity: 1,
            },
        },
        '& .simplebar-mask': {
            zIndex: 'inherit',
        },
    });
});
